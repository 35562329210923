import { ChatMessage } from "../common/types";

export default class APIClient {
  private baseURL: string;
  private chatId: string;
  private credential?: string;

  constructor(baseURL: string, defaultUser: string) {
    this.baseURL = baseURL;
    this.chatId = "";
  }

  setCredential(credential?: string) {
    this.credential = credential;
    if(credential && this.chatId) {
      this.login();
    }
  }

  public async chat(message: string): Promise<ChatMessage[]> {
    const resp = await fetch(`${this.baseURL}/chat/${this.chatId}`, {
      method: 'POST',
      body: JSON.stringify({
        message: message,
      }),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.credential || '',
      }
    });

    if (resp.status !== 200) {
      throw new Error('failed to communicate with server');
    }

    const data = await resp.json();
    if (data.chatId) {
      this.chatId = data.chatId;
    }

    return data.messages.map((a: { role: string, content: string, time: string, actions?: string[] }) => {
      return {
        role: a.role,
        content: a.content,
        time: new Date(a.time),
        actions: a.actions,
      }
    });
  }

  public async getPreviousChats(): Promise<ChatMessage[]> {
    const resp = await fetch(`${this.baseURL}/chat`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.credential || '',
      }
    });

    if (resp.status !== 200) {
      return [];
    }

    const data = await resp.json();
    if (data.chatId) {
      this.chatId = data.chatId;
    }

    return data.messages.map((a: { role: string, content: string, time: string, actions?: string[] }) => {
      return {
        role: a.role,
        content: a.content,
        time: new Date(a.time),
        actions: a.actions,
      }
    });
  }

  public async login(): Promise<ChatMessage[]> {
    if(!this.credential || !this.chatId) {
      throw new Error('no credential or chatId found');
    }

    const resp = await fetch(`${this.baseURL}/chat/${this.chatId}/login`, {
      method: 'POST',
      body: JSON.stringify({}),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.credential,
      }
    });

    if (resp.status !== 200) {
      throw new Error('failed to communicate with server');
    }

    const data = await resp.json();
    if (data.chatId) {
      this.chatId = data.chatId;
    }

    return data.messages.map((a: { role: string, content: string, time: string, actions?: string[] }) => {
      return {
        role: a.role,
        content: a.content,
        time: new Date(a.time),
        actions: a.actions,
      }
    });
  }
}