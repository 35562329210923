import {useCallback, useEffect, useState} from 'react';
import {
    createBrowserRouter,
    RouterProvider,
  } from "react-router-dom";
import Chat from './chat';
import APIClient from '../backend/api';
import I18nContext, {I18n, loadI18nAsset} from '../common/i18n-context';
import {v4 as uuid} from 'uuid';
import GoogleSSOContext, { GoogleSSO } from '../common/google-sso-context';
  
const apiClient = new APIClient(`${window.location.protocol}//${window.location.host}/api`, uuid());

const router = createBrowserRouter([
{
    path: "/",
    element: <Chat apiClient={apiClient}/>,
},
]);

export default function RootApp() {
    const [i18n, setI18n] = useState<I18n>(new I18n({}, true));
    const [googleSSO, setGoogleSSO] = useState<GoogleSSO>(new GoogleSSO());

    const loadAndApplyI18nAsset = useCallback(async () => {
        const asset = await loadI18nAsset();
        if(asset) {
            setI18n(new I18n(asset));
        } else {
            setI18n(new I18n({})); // with default value
        }
    }, [setI18n]);

    useEffect(() => {
        loadAndApplyI18nAsset();
    }, [loadAndApplyI18nAsset]);

    useEffect(() => {
        document.title = i18n.t('app_title', '问问');
    }, [i18n]);

    // setup google sso listener
    useEffect(() => {
        if(window.googleUser) {
            setGoogleSSO(new GoogleSSO(window.googleUser));
            apiClient.setCredential(window.googleUser.credential);
        }

        if(!window.googleSSOCallback) {
            window.googleSSOCallback = function(user) {
                setGoogleSSO(new GoogleSSO(user));
                apiClient.setCredential(user?.credential);
            }
        }
    }, [window.googleSSOCallback, window.googleUser]);

    return <I18nContext.Provider value={i18n}><GoogleSSOContext.Provider value={googleSSO}><RouterProvider router={router} /></GoogleSSOContext.Provider></I18nContext.Provider>;
}
