import React, { useState, useRef, useCallback, ChangeEvent, KeyboardEvent, useLayoutEffect, useContext, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import APIClient from '../../backend/api';
import i18nContext, { I18n } from '../../common/i18n-context';
import googleSSOContext, {GoogleSSO} from '../../common/google-sso-context';
import { ChatMessage } from '../../common/types';
import './index.css';

interface Props {
  apiClient: APIClient;
}

export default function Chat(props: Props) {
  const i18n = useContext<I18n>(i18nContext);
  const [chats, setChats] = useState<ChatMessage[]>([]);
  const [waitingForReply, setWaitingForReply] = useState<boolean>(false);
  const [inputMsg, setInputMsg] = useState<string>('');
  const [inputRows, setInputRows] = useState<number>(1);
  const [historyLoaded, setHistoryLoaded] = useState<boolean>(false);
  const historyRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const ssoContext = useContext<GoogleSSO>(googleSSOContext);

  useEffect(()=>{
    if(inputRef.current) {
      const isWrapped = inputRef.current.scrollHeight > inputRef.current.clientHeight;
      if(isWrapped) {
        // expand a few more lines into textarea when current buffer used up
        // this caps at 12 lines
        setInputRows(Math.min(inputRows + 3, 12));
      }
    }
  }, [inputMsg, inputRef, inputRows]);

  useEffect(() => {
    if(!ssoContext.isLogin()) {
      return;
    }

    if(chats.length) {
      return;
    }

    if(historyLoaded) {
      return;
    }

    props.apiClient.getPreviousChats().then(a => {
      setHistoryLoaded(true);
      setChats(a);
    });
  }, [ssoContext, chats, setChats, historyLoaded, setHistoryLoaded]);

  const onInputChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    const message = e.target.value;
    setInputMsg(message);
  }, []);

  const onInputKeyPress = useCallback(async (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if(e.key === 'Enter' && e.shiftKey) {
      return;
    }

    if (e.key === 'Enter' && inputMsg && !waitingForReply) {
      e.stopPropagation();
      e.preventDefault();
      const userMsg: ChatMessage = {
        role: 'user',
        content: inputMsg,
        time: new Date(),
      };
      if(!ssoContext.isLogin() && chats.length && Date.now() - chats[chats.length - 1].time.getTime() > 60 * 10 * 1000) {
        // if latest chat message happens 10min ago, we will clear it
        setChats([userMsg]);
      } else {
        setChats((prevChats) => [...prevChats, userMsg]);
      }
      setInputRows(1);
      setInputMsg('');
      setWaitingForReply(true);

      try {
        const repliedChats = await props.apiClient.chat(userMsg.content);
        setChats((prevChats) => [...prevChats, ...repliedChats]);
        setWaitingForReply(false);
      } catch (error) {
        const errorReply: ChatMessage = {
          role: 'assistant',
          content: i18n.t('error_get_reply', '好像出了点问题呢...刷新页面，重新开始试试吧。'),
          time: new Date(),
        };
        setChats((prevChats) => [...prevChats, errorReply]);
        setWaitingForReply(false);
      }
    }
  }, [inputMsg, waitingForReply, props.apiClient, i18n, chats, ssoContext]);

  useLayoutEffect(() => {
    historyRef?.current?.children[chats.length-1]?.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
  }, [chats]);

  return (
    <div className="chat-container">
      <div className="history-container" ref={historyRef}>
        {chats.map((chat, idx) => {
          return <Message key={`chat-${chat.role}-${idx}`} chat={chat} />;
        })}

        <div className="loading-container" hidden={!waitingForReply}>
          <div className="content-container">
            <Loading />
          </div>
        </div>
      </div>
      <div className="input-container">
        <textarea
          placeholder={i18n.t('input_placeholder', '请在这里输入你的问题')}
          className="chat-input"
          value={inputMsg}
          rows={inputRows}
          onChange={onInputChange}
          onKeyDownCapture={onInputKeyPress}
          ref={inputRef}
        />
        <a className="disclaimer" href="/privacy-policy.html">{i18n.t('privacy_policy', 'Privacy Policy')}</a>
      </div>
    </div>
  );
}

interface MessageProps {
  chat: ChatMessage;
}

function Message(props: MessageProps) {
  const i18n = useContext<I18n>(i18nContext);
  const ssoContext = useContext<GoogleSSO>(googleSSOContext);
  useEffect(()=>{
    if((props.chat.actions||[]).indexOf('GOOGLE_SSO') >= 0 && !ssoContext.isLogin()) {
      setTimeout(() => {
        window.google?.accounts.id.renderButton(document.getElementById(`sso_${props.chat.time.getTime()}`)!, {
          type: 'standard',
        });
      }, 50);
    }
  }, [ssoContext, props.chat]);

  return (
    <div className="message-container">
      <div className="author-container">
        <div className='author'>
          {props.chat.role === 'user' ? (ssoContext.loginUserName() ? ssoContext.loginUserName()+':' : i18n.t('user_title', '问:')) : i18n.t('reply_title', '答:')}
        </div>
        <div className='author-timestamp'>
          {props.chat.time.toLocaleTimeString()}
        </div>
      </div>
      <div className="content-container">
        <ReactMarkdown className="markdown">{props.chat.content}</ReactMarkdown>
        {(props.chat.actions||[]).indexOf('GOOGLE_SSO') >= 0 && !ssoContext.isLogin() ? 
          <div id={`sso_${props.chat.time.getTime()}`} className="g_id_signin"
              data-type="standard"
              data-shape="rectangular"
              data-theme="outline"
              data-text="signin_with"
              data-size="large"
              data-logo_alignment="left">
          </div> : null}
      </div>
    </div>
  );
}

function Loading() {
  return <img className="loading" src="./assets/loading.svg" alt="loading..." />;
}